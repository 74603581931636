import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import MoreDetails from "../components/MoreDetails/more";
import Features from "../components/Features/features";
import Banner from "../components/Banner/Banner"
import FAQ from "../components/FAQ/FAQ"
import "./index.css";


class HomePage extends Component {
  render() {
    return (
      <Layout>
        <div className="home">
          <HelmetProvider>
            <Helmet title={`صفحه نخست | ${config.siteTitle}`} />
          </HelmetProvider>
          <div className="homeContainer">
            <Banner />
            <MoreDetails />
            <Features />
            <FAQ />
          </div>         
        </div>
      </Layout>
    );
  }
}

export default HomePage;
