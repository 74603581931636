import React, { Component } from "react";
import firewall from './home/firewall.png'
import customize from './home/customize.png'
import vpn from './home/vpn.png'


import "./features.css";

class Features extends Component {
  render() {
    return (
			<div className="features">
        <div className="featuresContainer">
          <div>
            <img src={vpn} alt=""/>
            <h2>رایگان و نامحدود</h2>
            <p>استفاده از قابلیت‌هایی مشابه وی‌پی‌ان‌های تجاری، به‌صورت رایگان و البته نامحدود</p>
          </div>
          <div>
            <img src={firewall} alt=""/>
            <h2>دارای فایروال و امن</h2>
            <p>امکان پیکربندی فایروال داخل برنامه برای مدیریت اتصال برنامه‌ها به اینترنت</p>
          </div>
          <div>
            <img src={customize} alt=""/>
            <h2>شخصی‌سازی وی‌پی‌ان</h2>
            <p>امکان راه‌اندازی پل‌های ارتباطی خصوصی روی دامنه‌های شخصی و...</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
