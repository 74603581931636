import React, { Component } from "react";
import { Link } from "gatsby";
import Accordion from './../Accordion/Accordion';

import "./FAQ.css";

class FAQ extends Component {
  render() {
    return (
      <div className="faq">
      <h2>پرسش‌های متداول</h2>
      <Accordion>
        <div label='چرا فایل APK برنامه به صورت جداگانه برای دانلود وجود ندارد؟'>
          <p>در حال حاضر تیم فیلترشکن‌ها برای کنترل بهتر بروزرسانی‌ها در نظر دارد اپلیکیشن آرگو وی‌پی‌ان (ArgoVPN) را تنها از طریق گوگل پلی (Google Play) ارائه کند. توجه داشته باشید در صورت نصب آرگو‌ وی‌پی‌ان از طریق گوگل پلی، می‌توانید از قابلیت به‌روزرسانی سریع که حجم بسیار کمتری به نسبت به‌روزرسانی‌های عادی دارد، بهره ببرید.</p>
        </div>
        <div label='آیا آرگو وی‌پی‌ان (ArgoVPN) محدودیت حجم و سرعت دارد؟'>
          <p>خیر، در حال حاضر هیچگونه محدودیتی از نظر حجم، سرعت و یا زمان برای کاربران ArgoVPN اعمال نشده است.</p>
        </div>
        <div label='آیا آرگو وی‌پی‌ان باعث ناشناسی فعالیت‌های من در اینترنت می‌شود؟'>
          <p>خیر! توجه داشته باشید که ArgoVPN تنها یک ابزار عبور از سد فیلترینگ اینترنت است که بر روی سرعت و امنیت کاربران متمرکز است؛ با این حال نباید از آن به عنوان یک ابزار پنهان کردن هویت برای برقراری ارتباطات اینترنتی و انجام وب‌گردی ناشناس استفاده کرد. از این رو، تمامی مسئولیت‌های امنیتی مرتبط با استفاده نادرست و نابجا از برنامه ArgoVPN بر عهده خود کاربران است. توجه داشته باشید که هیچ وی‌پی‌ان‌ای امکان ناشناس‌سازی کامل هویت شما را در اینترنت ندارد و چنانچه تمایل دارید تمام فعالیت‌های اینترنتی شما به صورت ناشناس و کاملا امن انجام شود، باید از مرورگر تور استفاده کنید.</p>
        </div>        
      </Accordion>
      <Link to="/faq" className="link">بیشتر</Link>
      </div>
    );
  }
}

export default FAQ;
