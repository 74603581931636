import React, { Component } from "react";
import { Link } from "gatsby";

import "./more.css";

class MoreDetails extends Component {
  render() {
    return (
    	<div className="more">
        <h2>آرگو وی‌پی‌ان چیست؟</h2>
        <p>آرگو وی‌پی‌ان (ArgoVPN) یک برنامه وی‌پی‌ان رایگان و فاقد محدود حجمی برای دستگاه‌های اندرویدی است که با استفاده از آن می‌توانید به راحتی به تمام وب‌سایت‌ها و سرویس‌های اینترنتی فیلترشده، شبکه‌های اجتماعی و پیام‌رسان‌ها دسترسی پیدا کنید. این برنامه براساس نیاز کاربران ایرانی و با توجه به سازوکار فیلترینگ اینترنت ایران طراحی شده است که امکانات متنوعی برای دسترسی به اینترنت در شرایط مختلف را برای شما فراهم می‌کند.</p>
        <Link to="/blog">بیشتر</Link>
      </div>
    );
  }
}

export default MoreDetails;
